/**
 *  UI-REFACTORED
 *    FROM --> ./legacy-web/src/main/js/js/aa/modules/utilities.js
 *    TO   --> ./webapps/shared-web/src/main/assets/js/modules/utilities.js
 **/

/**
  @class BroadcastChannel
  @see <a href="https://developer.mozilla.org/en-US/docs/Web/API/Broadcast_Channel_API">Broadcast Channel API on MDN</a>
  @author Joshua Bell
*/

(function(global) {
    var channels = [];

    function BroadcastChannel(channel) {
        var $this = this;
        channel = String(channel);

        var id = '$BroadcastChannel$' + channel + '$';

        channels[id] = channels[id] || [];
        channels[id].push(this);

        this._name = channel;
        this._id = id;
        this._closed = false;
        this._mc = new MessageChannel();
        this._mc.port1.start();
        this._mc.port2.start();

        global.addEventListener('storage', function(e) {
            if (e.storageArea !== global.localStorage) return;
            if (e.newValue == null || e.newValue === '') return;
            if (e.key.substring(0, id.length) !== id) return;
            var data = JSON.parse(e.newValue);
            $this._mc.port2.postMessage(data);
        });
    }

    BroadcastChannel.prototype = {
        // BroadcastChannel API
        get name() {
            return this._name;
        },
        postMessage: function(message) {
            var $this = this;
            if (this._closed) {
                var e = new Error();
                e.name = 'InvalidStateError';
                throw e;
            }
            var value = JSON.stringify(message);

            // Broadcast to other contexts via storage events...
            var key = this._id + String(Date.now()) + '$' + String(Math.random());
            global.localStorage.setItem(key, value);
            setTimeout(function() {
                global.localStorage.removeItem(key);
            }, 500);

            // Broadcast to current context via ports
            channels[this._id].forEach(function(bc) {
                if (bc === $this) return;
                bc._mc.port2.postMessage(JSON.parse(value));
            });
        },
        close: function() {
            if (this._closed) return;
            this._closed = true;
            this._mc.port1.close();
            this._mc.port2.close();

            var index = channels[this._id].indexOf(this);
            channels[this._id].splice(index, 1);
        },

        // EventTarget API
        get onmessage() {
            return this._mc.port1.onmessage;
        },
        set onmessage(value) {
            this._mc.port1.onmessage = value;
        },
        addEventListener: function(/*type, listener , useCapture*/) {
            return this._mc.port1.addEventListener.apply(this._mc.port1, arguments);
        },
        removeEventListener: function(/*type, listener , useCapture*/) {
            return this._mc.port1.removeEventListener.apply(this._mc.port1, arguments);
        },
        dispatchEvent: function(/*event*/) {
            return this._mc.port1.dispatchEvent.apply(this._mc.port1, arguments);
        },
    };

    global.BroadcastChannel = global.BroadcastChannel || BroadcastChannel;

})(window);


/* A module that houses several general js utilities */

AAcom.modules.utilities = function(AAUI) {

    /* common event bindings */
    AAUI.onClick = function(selector, callback) {
        $j(document).on('click', selector, function(e) {
            this.callback = callback;
            var result = this.callback(e) || false;

            return result;
        });
    };
    AAUI.onChange = function(selector, callback) {
        $j(document).on('change', selector, callback);
    };
    AAUI.onMouseEnter = function(selector, callback) {
        $j(document).on('mouseenter', selector, callback);
    };
    AAUI.onMouseLeave = function(selector, callback) {
        $j(document).on('mouseleave', selector, callback);
    };
    AAUI.onFocus = function(selector, callback) {
        $j(document).on('focus', selector, callback);
    };
    AAUI.onBlur = function(selector, callback) {
        $j(document).on('blur', selector, callback);
    };
    AAUI.onSubmit = function(selector, callback) {
        $j(document).on('submit', selector, callback);
    };
    AAUI.onKeyup = function(selector, callback) {
        $j(document).on('keyup', selector, callback);
    };
    AAUI.onKeyPress = function(selector, callback) {
        $j(document).on('keypress', selector, callback);
    };
    AAUI.onKeyChange = function(selector, callback) {
        AAUI.onChange(selector, callback);
        AAUI.onKeyup(selector, function(e) {
            var tabKey = 9, shiftKey = 16;
            if(!(e.keyCode == tabKey || e.keyCode == shiftKey)) {
                $j(this).trigger('change');
            }
        });
    };
    AAUI.onInput = function(selector, callback) {
        $j(document).on('input', selector, callback);
        if($j('html').hasClass('lt-ie10')) {
            // workaround for ie9 not recognizing del, backspace, cut as input triggers
            $j(document).on('cut', selector, callback);
            $j(document).on('keyup', selector, function(e) {
                var keyCode = e.keyCode || e.which;
                if(keyCode == 8 || keyCode == 46) {
                    this.callback = callback;
                    this.callback(e);
                }
            });
        }
    };

    AAUI.getUrlParameter = function(sParam) {
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split('&');
        for (var i=0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split('=');
            if (sParameterName[0] == sParam) {
                return sParameterName[1];
            }
        }
    };

    AAUI.off = function(event, selector, callback) {
        $j(document).off(event, selector, callback);
    };
    /* A utility to create generic "new window" popups (not to be confused with modal windows) */
    var genericPopupUtility = {
        popup: function(windowUrl, windowName, windowSize, windowWidth, windowHeight) {
            // declare vars used in function
            var windowLeft = 0,
                windowTop = 0,
                windowFeatures = '',
                windowObject = null;

            // set default values for passed arguments
            windowUrl = windowUrl || '';
            windowName = windowName || '';
            windowSize = windowSize || 'MEDIUM';
            windowWidth = windowWidth || this.defaultWidth;
            windowHeight = windowHeight || this.defaultHeight;

            // convert window size string to uppercase
            windowSize = windowSize.toUpperCase();

            // use window width and height vars as integers
            windowWidth = parseInt(windowWidth);
            windowHeight = parseInt(windowHeight);

            // determine the window size
            switch(windowSize) {
                case 'SMALL':
                    windowWidth = 300;
                    windowHeight = 300;
                    break;

                case 'MEDIUM':
                    windowWidth = this.defaultWidth;
                    windowHeight = this.defaultHeight;
                    break;

                case 'LARGE':
                    windowWidth = 850;
                    windowHeight = 500;
                    break;

                case 'SCREEN':
                    windowWidth = $j(window).width() - 50;
                    windowHeight = $j(window).height() - 50;
                    break;
            }

            // determine the coords to center the window
            windowLeft = Math.floor(screen.availWidth / 2) - Math.floor(windowWidth / 2);
            windowTop = Math.floor(screen.availHeight / 2) - Math.floor(windowHeight / 2);

            // begin building window features
            if(windowSize === 'SCREEN') {
                windowFeatures = 'location=1, status=1, scrollbars=1, menubar=1, toolbar=1, resizable=1';
            }else{
                windowFeatures = 'location=0, status=0, scrollbars=1, menubar=0, toolbar=0, resizable=1';
            }

            // add the rest of the window features
            windowFeatures =
                windowFeatures +
                ',width=' + windowWidth +
                ',height=' + windowHeight +
                ',top=' + windowTop +
                ',left=' + windowLeft;

            // ie9 and down don't support actually having anything in the window name argument
            if($j('html').hasClass('lt-ie10')) {
                windowName = 'popupWindow';
            }

            // open the window
            windowObject = window.open(
                windowUrl,
                windowName,
                windowFeatures
            );

            // focus on the window
            if(windowObject !== null) {
                windowObject.focus();

                return windowObject;
            }
        },
        defaultWidth: 675,
        defaultHeight: 500
    };

    AAUI.genericPopup = function(element) {
        element.off('click').on('click', function() {

            // declare size var
            var size = '';

            // determine the size based on class or passed data attribute
            if(!$j(this).attr('data-size')) {
                switch(true) {
                    case $j(this).hasClass('aa-pop-win-sm'):
                        size = 'SMALL';
                        break;
                    case $j(this).hasClass('aa-pop-win-med'):
                        size = 'MEDIUM';
                        break;
                    case $j(this).hasClass('aa-pop-win-lrg'):
                        size = 'LARGE';
                        break;
                    case $j(this).hasClass('aa-pop-win-new'):
                        size = 'SCREEN';
                        break;
                    default:
                        size = 'MEDIUM';
                }
            }
            else{
                size = $j(this).attr('data-size');
            }

            // generate popup
            genericPopupUtility.popup($j(this).attr('href'), $j(this).attr('title'), size, $j(this).attr('data-width'),
                $j(this).attr('data-height'));

            // prevent link default
            return false;
        });
    };

    AAUI.genericManualPopup = function(windowUrl, windowName, windowSize) {
        genericPopupUtility.popup(windowUrl, windowName, windowSize, 0, 0);
    };

    AAUI.getQueryStringParameterByName = function(name) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        var results = regex.exec(location.search);

        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    AAUI.populateFormFromJSON = function(data) {
        $j.each(data, function(name, val) {
            var $el = $j('[name="' + name + '"]'),
                type = $el.attr('type');

            switch(type) {
                case 'checkbox':
                    // assumption that 'unchecked' checkbox data returned won't match value but 'checked' will
                    $el.removeAttr('checked');// reset the checkbox first
                    $el.filter('[value="' + val + '"]').attr('checked', 'checked');
                    break;
                case 'radio':
                    $el.filter('[value="' + val + '"]').attr('checked', 'checked');
                    break;
                default:
                    $el.val(val).trigger('change');
            }
        });
    };

    AAUI.populateFormErrorsFromJSON = function(formID, data) {
        $j.each(data, function(name, message) {
            var globalErrorStartsWith = 'ERRCODE';

            if(name.substring(0, globalErrorStartsWith.length) === globalErrorStartsWith) {
                // Global errors
                $errorContainer = $j(formID + 'Errors');

                // display error message
                $errorContainer.append(message)
                .addClass('message-inline-error')
                .removeClass('is-hidden');
            }else{

                // Form field errors
                var $el = $j('[name="' + name + '"]:visible'),
                    errorsID = name + 'Errors',
                    errorsID = errorsID.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]").replace(/\./g, '\\.'),
                    proxyLabelID = $el.attr('data-proxy-label'), // ID of something other than label tag used as label
                    $errorContainer = $j('#' + errorsID);

                // set error class on input's label or proxy-label
                $j('label[for=\'' + $el.attr('id') + '\']').addClass('is-error');
                $j('#' + proxyLabelID).addClass('is-error');

                // display error message
                $errorContainer.append(message).addClass('is-error -message');

                // handle composite errors
                if($errorContainer.data('composite_error')) {
                    $errorContainer.parent().find('.js-compositeFieldError').append(message);  // Add error msg to composite form fields
                }

                // Display Error Summary Links
                AAUI.errorSummary.showLink(name);

            }
        });
    };

    AAUI.clearFormErrors = function(container) {
        var $container = $j(container);
        $container.find('.is-error.-message').empty();
        $container.find('.is-error').removeClass('is-error').removeClass('-message');
        $container.find('.message-inline-error').removeClass('message-inline-error');
        var errorMessageContainers = $container.find('[id$="Errors"],[id$=".errors"]');
        $j.each(errorMessageContainers, function() {
            $j(this).empty().removeClass('message-inline-error');
            $j(this).parent().find('.js-compositeFieldError').empty(); // Remove composite form field errors
        });
    };

    AAUI.clearInputValueAndErrors = function(whichInputID) {
        var thisInput = $j(whichInputID),
            thisInputErrors = $j(whichInputID + 'Errors');

        thisInput.val('').removeClass('is-error');
        thisInputErrors.html('').removeClass('is-error').removeClass('-message');
        $j('label[for=\'' + thisInput.attr('id') + '\']').removeClass('is-error');
    };

    AAUI.resetFormFields = function(container) {
        $j(container).find(':input').each(function() {
            var $el = $j(this),
                type = $el.attr('type');
            switch(type) {
                case 'checkbox':
                    $el.removeAttr('checked').trigger('change');
                    break;
                case 'radio':
                    if(!$el.hasClass('js-pillbox')) {
                        $el.removeAttr('checked');
                    }
                    break;
                case 'text':
                    $el.val('').trigger('change');
                    break;
                default:
                    // handle select boxes
                    $el.prop('selectedIndex', 0).trigger('change');
            }
        });
    };

    AAUI.setDialogTitle = function(whichDialogID, titleString) {
        $j(whichDialogID).dialog('option', 'title', titleString);
    };

    AAUI.ignoreChangesDialog = function() {
        var self = {};
        self.init = function() {
            var buttons = [
                {name: AAUI.getProperty('btn.yes'), cssClass: 'btn', callback: self.reload, closeDialog: false},
                {name: AAUI.getProperty('btn.no'), cssClass: 'btn'}
            ];
            AAUI.util.aaDialog('ignoreChanges', {
                width: 'small',
                buttons: buttons,
                toggleScroll: true
            });
            AAUI.onClick('.js-ignoreChanges', self.open);
        };
        self.open = function() {
            self.href = $j(this).attr('data-href');
            AAUI.util.aaDialog('ignoreChanges').openDialog();
        };
        self.reload = function() {
            window.location.href = self.href;

            return false;
        };

        return self;
    }();

    AAUI.tier1 = function() {
        var self = {};
        self.setFocus = function(selector) {
            selector = selector || '.message-error,.message-warning,.message-info';
            var $tier1Heading = $j(selector).filter(':visible:first').find('.header');
            AAUI.setFocusOn($tier1Heading);
        };

        return self;
    }();

    AAUI.errorSummary = function() {
        var self = {},
            _errorSummary = '.js-errorSummary';
        self.init = function(pageLoad) {
            AAUI.onClick(_errorSummary + ' a', self.clickLink);
        };
        self.setFocus = function() {
            AAUI.tier1.setFocus('.message-error');
        };
        self.clickLink = function() {
            var target = $j(this).attr('href').replace(/\./g, '\\.').replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
            AAUI.setFocusOn(target);
        };
        self.hideLinks = function(container) {
            $j(container).find(_errorSummary + ' li').addClass('is-hidden');
        };
        self.showLink = function(name) {
            $j(_errorSummary + ' a[id="' + name + '.link"]').parents('li').removeClass('is-hidden');
        };

        return self;
    }();

    AAUI.setFocusOn = function(target) {
        var $target = $j(target).filter(':first:visible');
        if($target.length > 0) {
            if($target.filter(':input,a').length === 0 && $target.attr('tabindex') === undefined) {
                $target.attr('tabindex', '-1');
            }
            if($target.parents('.ui-dialog:first').length === 0) {
                var topPosition = $target.parent().offset().top - 50;
                $j('html,body').animate({scrollTop: topPosition});
            }
            $target.focus();
        }
    };

    AAUI.sessionTimeOutDialog = (function() {
        var self = {},
            sessionTimeOutChannel = new BroadcastChannel('sessionTimeOutChannel'),
            aaUtilities,
            $sessionTimeoutDialog,
            $title,
            $body,
            $continueButton,
            $homeButton,
            $isDisplayVirtualPNRModal,
            $isFinalDisplayModal,
            $bookingPathStateId,
            _dialogID = '#sessionTimeOutDialog',
            $appStartOverButton,
            sessionTimer;

        self.init = function() {
            $j(document).ready(function() {
                if($j(_dialogID).filter('.js-spring').length > 0 && $j(_dialogID).parent('.ui-dialog').length === 0) {
                    aaUtilities = new aa_Utilities();
                    var buttons = [
                        {
                            name: AAUI.getProperty('session.warning.button'),
                            cssClass: "btn no-margin",
                            callback: function() { self.continueSession(); postMessage({cmd: 'continueSession'}); },
                            closeDialog: false
                        },
                        {
                            name: AAUI.getProperty('session.expired.button'),
                            cssClass: "btn no-margin is-hidden",
                            callback: self.home,
                            closeDialog: false
                        },
                        {
                            name: AAUI.getProperty("session.expired.app.button"),
                            cssClass: "btn no-margin is-hidden",
                            callback: self.appStartOver,
                            closeDialog: false
                        }
                    ];
                    aaUtilities.aaDialog('sessionTimeOut', {
                        width: 'small',
                        buttons: buttons,
                        toggleScroll: true,
                        showClose: false,
                        closeOnEscape: false,
                        showFullScreen: true,
                        hideCloseInApp: true,
                        zIndex: 99999
                    });

                    sessionTimeOutChannel.onmessage = function(e) {
                        switch(e.data.cmd) {
                            case 'showSessionTimeOutDialog':
                                self.open();
                                break;
                            case 'continueSession':
                                self.continueSession();
                                break;
                            case 'resetTimersToContinue':
                                self.startSessionTimer();
                                break;
                            case 'sessionExpiredDialog':
                                self.sessionExpiredDialog();
                            default:
                                break;
                        }
                    };

                    self.sessionDialogElements();
                    self.startSessionTimer();
                }
            });
        };
        self.sessionDialogElements = function() {
            $sessionTimeoutDialog = jQuery(_dialogID).parents(".ui-dialog:first");
            $title = $sessionTimeoutDialog.find(".ui-dialog-title");
            $body = $sessionTimeoutDialog.find(".js-dialogBody");
            $continueButton = $sessionTimeoutDialog.find(_dialogID + 'Button0');
            $homeButton = $sessionTimeoutDialog.find(_dialogID + 'Button1');
            $appStartOverButton = $sessionTimeoutDialog.find(_dialogID + 'Button2');
            $isDisplayVirtualPNRModal = AAUI.getProperty('isDisplayVirtualPNRModal');
            $isFinalDisplayModal = AAUI.getProperty('isFinalDisplay');
            $bookingPathStateId = AAUI.getProperty('bookingPathStateId');
        };
        self.open = function() {
            aaUtilities.aaDialog('sessionTimeOut').openDialog();
            self.startSessionRenewTimer();
        };
        self.startSessionTimer = function() {
            var sessionMaxInactiveTime = (AAUI.getProperty('sessionTimeOut') * 1000) - 5000;
            var sessionWarningCountDown = sessionMaxInactiveTime - AAUI.getProperty('sessionExpiresWarningInterval');

            stopTimers();
            if (self.bypassContinueSessionWarning()) {
                sessionTimer = setTimeout(function() { self.sessionExpiredDialog(); postMessage({cmd: 'sessionExpiredDialog'}); }, sessionMaxInactiveTime);
            } else {
                sessionTimer = setTimeout(function() { self.open(); postMessage({cmd: 'showSessionTimeOutDialog'}); }, sessionWarningCountDown);
            }
        };
        self.startSessionRenewTimer = function() {
            stopTimers();
            sessionTimer = setTimeout(function() { self.sessionExpiredDialog(); postMessage({cmd: 'sessionExpiredDialog'}); }, AAUI.getProperty('sessionExpiresWarningInterval'));
        };
        self.sessionExpiredDialog = function() {
            self.stopTimers();
            $title.html(AAUI.getProperty('session.expired.title'));
            $body.html(AAUI.getProperty('session.expired.message'));
            if (typeof $device !== "undefined" && $device.mobileApp) {
                $continueButton.addClass("is-hidden");
                $homeButton.addClass("is-hidden");
                $appStartOverButton.removeClass("is-hidden");
            } else {
                $continueButton.addClass("is-hidden");
                $homeButton.removeClass("is-hidden");
            }

            // Close and reopen dialog so that screen readers will read it
            aaUtilities.aaDialog('sessionTimeOut').closeDialog();
            aaUtilities.aaDialog('sessionTimeOut').openDialog();
            if (self.virtualPNRExists()) {
                deleteVirtualPNR();
            }

            $j.ajax({
                url: '/shared/session/expired/',
                type: "POST",
                dataType: 'text',
                timeout: 50000,
                success: function() {
                    self.stopTimers();
                },
                error: function() {
                    self.stopTimers();
                    window.location.href = '/homePage.do';
                }
            });
        };
        self.continueSession = function() {
            self.stopTimers();
            var params = self.createVirtualPnrParams();
            $j.ajax({
                url: '/shared/session/renew/' + params,
                type: 'POST',
                dataType: 'json',
                timeout: 50000,
                success: function(jsonResponse) {
                    if (self.virtualPNRExists()) {
                        self.handleUpdateVirtualPnrResponse(jsonResponse);
                    } else {
                        self.resetTimersToContinue();
                    }
                },
                error: function(xhr, statusText) {
                    self.stopTimers();
                    self.open();
                    self.sessionExpiredDialog();
                }
            });

            aaUtilities.aaDialog('sessionTimeOut').closeDialog();

            return false;
        };

        self.createVirtualPnrParams = function() {
            if (self.virtualPNRExists()) {
                return '?virtualPnr=true&bookingPathStateId=' + $bookingPathStateId;
            }

            return '';
        };

        self.stopTimers = function() {
            stopTimers();
            postMessage({cmd: 'stopTimers'});
        };

        self.resetTimersToContinue = function() {
            self.startSessionTimer();
            postMessage({cmd: 'resetTimersToContinue'});
        };

        self.virtualPNRExists = function() {
            return ('true' == $isDisplayVirtualPNRModal || $isDisplayVirtualPNRModal);
        };

        self.bypassContinueSessionWarning = function() {
            return ('true' == $isFinalDisplayModal || $isFinalDisplayModal);
        };

        self.handleUpdateVirtualPnrResponse = function(response) {
            $isFinalDisplayModal = response.finalVirtualPNRModal;
            self.resetTimersToContinue();
        };

        self.home = function() {
            window.location.href = '/homePage.do';
        };

        self.appStartOver = function() {
            //Send message to the app for session expiration.
            $j(document).trigger("bridge.aacomToNativeApp", {
                name: "sessionExpired"
            });
        };

        var stopTimers = function() {
            clearTimeout(sessionTimer);
            sessionTimer = null;
        };

        var postMessage = function(cmd) {
            sessionTimeOutChannel.postMessage(cmd);
        }

        return self;
    })();

    AAUI.characterCount = function() {
        self.init = function() {
            AAUI.onInput('.js-commentsArea', self.calculate);
            AAUI.onInput('.js-assistanceTypeCommentsArea', self.assistanceTypeCalculate);
        };
        self.calculate = function() {
            var $textField = $j(this),
                maxLength = $textField.attr("maxlength"),
                usedLength = $textField.val().length;
            $j('.js-characterCounter').html(maxLength - usedLength);
        };
        self.assistanceTypeCalculate = function() {
            var $textField = $j(this),
                maxLength = $textField.attr("maxlength"),
                usedLength = $textField.val().length;
            $j('.js-assistanceTypeCharacterCounter').html(maxLength - usedLength);
        };

        return self;
    }();

    AAUI.toPascalCase = function(content) {

        var formattedContent = AAUI.pascalCaseContent(content);

        formattedContent = formattedContent.replace("-A-", "-a-");
        formattedContent = formattedContent.replace("(u.s.)", "(U.S.)");
        formattedContent = formattedContent.replace("(british)", "(British)");

        return formattedContent.trim();
    };

    AAUI.pascalCaseContent = function(content) {

        var splitter = " ";
        var formattedWord = "";
        var formattedContent = "";

        var wordArray = content.split(splitter);

        var lastWordIndex = wordArray.length - 1;

        for(var i = 0; i < wordArray.length; i++) {

            formattedWord = AAUI.pascalCaseWord(wordArray[i]);
            formattedWord = AAUI.pascalCaseContentWithSplitter("-", formattedWord);
            formattedWord = AAUI.pascalCaseContentWithSplitter("/", formattedWord);

            //attach formatted word to content
            formattedContent += formattedWord;

            //if not last word then add splitter
            if(i != lastWordIndex) {
                formattedContent += splitter;
            }
        }

        return formattedContent.trim();
    };
    AAUI.pascalCaseContentWithSplitter = function(splitter, content) {

        if(content.indexOf(splitter) == -1) {
            return content;
        }

        var formattedWord;
        var formattedContent = "";

        var wordArray = content.split(splitter);
        var lastWordIndex = wordArray.length - 1;

        for(var x = 0; x < wordArray.length; x++) {

            formattedWord = AAUI.pascalCaseWord(wordArray[x]);

            //attach formated word to content
            formattedContent += formattedWord;

            //if not last word then add splitter
            if(x != lastWordIndex) {
                formattedContent += splitter;
            }
        }

        return formattedContent.trim();
    };

    AAUI.pascalCaseWord = function(word) {
        return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase();
    };

    //immediately execute what is needed on every page
    AAUI.genericPopup($j('.aa-pop-win-med'));
    AAUI.genericPopup($j('.aa-pop-win-lrg'));
    AAUI.errorSummary.init();
    AAUI.sessionTimeOutDialog.init();
};
